import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';

import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';

import {Page} from '../../components';
import SearchPage from '../SearchPage/SearchPage';

import { isScrollingDisabled } from '../../ducks/UI.duck';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings,
    onManageDisableScrolling,
    pagination,
    searchInProgress,
    searchListingsError,
    onActivateListing,
    searchParams,
    filterConfig,
    sortConfig,
    isAuthenticated,
    currentUser,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'LandingPage.schemaTitle' },
    { siteTitle }
  );
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      {/* <LayoutSingleColumn> */}
      {/* <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar> */}
      {/* <LayoutWrapperMain> */}
      <SearchPage
        intl={intl}
        listings={listings}
        filterConfig={filterConfig}
        sortConfig={sortConfig}
        history={history}
        location={location}
        mapListings={[]}
        onManageDisableScrolling={onManageDisableScrolling}
        pagination={pagination}
        scrollingDisabled={scrollingDisabled}
        searchInProgress={searchInProgress}
        searchListingsError={searchListingsError}
        searchParams={searchParams}
        activeListingId=""
        currentUser={currentUser}
        isAuthenticated={isAuthenticated}
        onActivateListing={onActivateListing}
      />
      {/* <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div> */}
      {/* </LayoutWrapperMain> */}
      {/* <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter> */}
      {/* </LayoutSingleColumn> */}
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
